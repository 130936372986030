.project-page {
    height: 100%;
    animation: 2s fade-in ease
}



@keyframes fade-in {
    0% {
        background-color: rgba(24, 24, 24, 0);
    } 

    100% {
        background-color: rgba(24, 24, 24, 0.5);
    }
}

@keyframes load-in {
    0% {
        opacity: 0
    }

    75% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.project-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    gap: 24px;
    background-color: rgba(24, 24, 24, 0.5);
    padding-top: 24px;
    margin: 10vh 0;
}

.project-photo {
    width: 45%;
    max-height: 300px;
    background-color: white;
    border: 12px solid #2b2b2b;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1s ease-out;
    padding: 16px;
    animation: 4s load-in
}

.project-photo img {
    max-width: 80%;
    max-height: 300px;
    transition: transform 1s ease-out;
}

.project-photo:hover {
    transform: scale(.90);
    cursor: pointer;
  }

.project-photo img:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.project-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
}

.title {
    font-size: xx-large;
    animation: 3s load-in
}

.description {
    font-size: x-large;
    text-align: center;
    animation: 3s load-in
}

.skill-container ul{
    display: flex;
    gap: 24px;
    justify-content: center;
    padding: 24px 0;
    list-style: none;
    flex-wrap: wrap;
}

.skill-container li {
    list-style: none;
    display: flex;
    align-items: center;
}

.skill {
    background-color: white;
    color: black;
    padding: 8px 16px;
    border-radius: 24px;
    animation: 2s load-in
}

.skill:hover {
    cursor: default;
}

.skills {
    display: flex;
    color: black;
    justify-content: flex-start;
    gap: 24px;
    padding-top: 12px;
}



