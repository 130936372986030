body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./images/mountains.webp');
  background-size: cover;
  background-attachment: fixed;
  caret-color: transparent;
}

@media (max-width: 900px) {
  body {
    background-image: none;
    background-color: rgba(24, 24, 24, 1);
  }
}

@media(max-width: 500px) {
  body {
    background-image: none;
    background-color: rgba(24, 24, 24, 1);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
