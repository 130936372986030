#create-project-page {
  display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Imbue', serif;
}

.create-project-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .create-project-container h2 {
    font-family: 'Imbue', serif;
    font-size: 48px;
  }
  
  #project-form {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  .form-inputs {
    display: flex;
    justify-content: center;
    gap: 96px;
  }

  .form-inputs div {
    display: flex;
    flex-direction: column;
    gap: 64px
  }
  
  label {
    font-size: 24px;
    margin-top: 20px;
    font-family: 'Inria', serif;
  }
  
  input,
  textarea {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  textarea {
    height: 150px;
  }

  button {
    width: 75%;
    padding: 4px 0;
    border: none;
    background-color: rgba(43, 43, 43, .75);
    color: white;
    border-radius: 2px;
}

button:hover {
    cursor: pointer;
    background-color: rgba(43, 43, 43, 1)
}

@media (max-width: 900px) {
  .vertical {
    flex-direction: column;
  }

  #create-project-page {
    gap: 128px;
  }
}

#button-container {
  display: flex;
  gap:24px;
  width: 100%;
}
  