.App {
  display: flex;
  flex-direction: column;
  background-size: cover;
  animation: 3s open ease;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html:-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.content {
  display: flex;
  flex-direction: column;
  background-size: cover;
  width: 100%;
}

@keyframes open {
  0% {
    background-color: rgba(24, 24, 24, 1);
  }

  100% {
    background-color: rgba(24, 24, 24, 0);
  }
}

@keyframes slideInTopRight {
  0% {
    transform: translateX(100%)
  }

  50% {
    transform: translateX(-20%);
  }

  100% {
    transform: translateX(0%)
  }
}

@keyframes slideInTopLeft {
  0% {
    transform: translateX(-100%)
  }

  50% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideInBottomLeft {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideInBottomRight {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%
}

.navbar-container {
 z-index: 999;
}

.navbar-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 96px 0 48px;
  position: fixed;
  top: 0;
  width: 90%;
  z-index: 999;
}

.navbar-left {
  font-size: x-large;
  padding: 12px 0;
  animation: 1s slideInTopLeft 
}

#navbar a {
  text-decoration: none;
  color: white
}

.navbar-right {
  display: flex;
  align-items: center;
  animation: 1s slideInTopRight 
}

#menu-icon {
  cursor: pointer;
  padding: 0 2px;
  transition: background-color 1s ease-out;
}

#menu-icon:hover {
  background-color: rgba(0,0,0,.25);
}

#close-icon {
  cursor: pointer;
  transition: background-color 1s ease-out;
}

#close-icon:hover {
  background-color: rgba(0,0,0,.25);
}

#hamburger-menu {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  backdrop-filter: blur(3px);
  background-color: rgba(0,0,0,.25);
  padding: 96px 48px 12px 0;
  z-index: -1;

}

.phase-in {
  animation: 1s phase-in ease;
}

.phase-out {
  animation: 1s phase-out ease;
}

.animate-class {
  animation: phase-in-rise 2s
}

.show-element {
  visibility: visible;
}

@keyframes phase-in-rise {
  0% {
    opacity: 0;
    transform: translateY(10%)
  } 

  25% {
    opacity: 0;
    transform: translateY(10%)
  }
  
  100% {
    opacity: 1
  }
}

@keyframes phase-in {
  from {
    opacity: 0
  } 
  
  to {
    opacity: 1
  }
}

@keyframes phase-out {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

#hamburger-menu ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  list-style: none;
  gap: 24px;
  font-weight: bold;
  font-size: large;
}



#navbar-links {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 48px;
  font-size: large;
}

.navbar-right li {
  display: inline-block;
  position: relative;
  transition-property: text-decoration;
  transition-duration: 1s;
  transition-timing-function: ease-out;
}

.navbar-right li::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #cecec2;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;

}

.navbar-right li:hover::after {
  cursor: pointer;
  color: #cecec2;
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar-right li:hover {
  cursor: pointer;
}

.navbar-left h2 {
  font-weight: 700;
}

.navbar-left h2:hover {
  cursor: pointer;
}



.hidden {
  visibility: hidden;
}

/* Home Page */

.home-page {
  width: 100%;
  color: white;
  display: flex;
  padding: 25vh 0 20vh 0;
  justify-content: center;
}

.home-page a {
  text-decoration: none;
  color: white;
}

.home-page-top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
  gap: 15%;
}

.home-page-right {
  font-size: 48px;
  background-color: rgba(0,0,0,.1);
  padding: 16px;
  transition: background-color 1s;
  animation: 3s slideInBottomRight ease
}

.home-page-right:hover {
  background-color: rgba(0,0,0,.25);
  cursor: default;
}

.home-page-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 128px 0 24px 0;
  position: relative; /* Added for the pseudo-element */
  max-width: 60%;
}

.home-page-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 2px solid white;
  box-sizing: border-box;
  animation: drawBorder 4s linear forwards;
}

.home-page-left hr {
  width: 50%;
  border-width: .5px;
  animation: titleSpread 6s ease-out;
}

@keyframes titleSpread {
  0% {
    width: 0;
  }

  50% {
    width: 0;
  }

  100% {
    width: 50%;
  }
}

.home-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 128px;
  animation: 2.5s slideInBottomRight;
  z-index: 999;
}

@keyframes social-fade {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.home-socials>*:hover {
  color: #cecec2;
  cursor: pointer;
  transform: scale(1.2);
  transition: transform .5s ease-out
}

.github {
  font-size: 64px;
}

.home-title {
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  color: white;
  z-index: -2;
  animation: 2s slideInBottomLeft ease
}

.home-description {
  font-size: 1rem;
  color: white;
  z-index: -2;
  animation: 2s slideInBottomLeft ease;
  text-align: center;
  max-width: 75%;
  line-height: 200%;
}

@keyframes drawBorder {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    width: 0;
    height: 0;
  }

  75% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

#about {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 96px;
  padding: 0 20vw;
  padding-bottom: 96px;
}

#portrait {
  width: 337px;
  z-index: -3;
  filter: grayscale(100%);
}

.about-left {
  border: solid 2px white;
  padding: 24px;
}

.pre-fade {
  opacity: 0;
}

.fade-in {
  opacity: 1;
  animation: 1.5s fade ease;
}

@keyframes fade {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.about-right {
  color:white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 20px;
}

/* Projects */

#projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 10vh 0 20vh 0;
}

.hr-spread {
  width: 50%;
  animation: 1.5s spread ease
}

@keyframes spread {
  from {
    width: 0%
  }

  to {
    width: 50%
  }
}

@media(max-width: 900px) {
  .description {
    align-items: center;
  }
}

#projects .title {
  font-size: x-large;
  padding-top: 5vh
}

.works {
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

/* Preview */

.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10%;
  list-style: none;
  /* overflow: hidden; */
}

.preview-photo {
  min-width: 35%;
  height: 300px;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease-out;
  padding: 0 12px;
}

.preview ul {
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 24px 0;
  list-style: none;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.preview-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .preview-photo:hover {
  transform: scale(.95);
  cursor: pointer;
} */

.preview-photo img {
  height: 240px;
  max-width: 500px;
  transition: transform 1s ease-out;
}

.preview-photo img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.preview .description {
  font-size: large;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  text-align: left;
  width: 20%;
}

.project-link a {
  color: black;
  text-decoration: none;
}

.project-link {
  transition: all .3s ease-in-out;
  box-sizing: border-box; 
  border: solid 2px black;
}

.project-link:hover {
  border: solid 2px white;
  background-color: black;
  color: white !important;
  cursor: pointer;
}

.project-link:hover a {
  color: white !important;
}


/* Footer */

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  gap: 15%;
  padding: 2vh 0;
  /* background-color: rgba(24, 24, 24, 1); */
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer-before {
  background-color: rgba(24, 24, 24, 1);
}

.footer-fade {
  animation: 3s fade-in-footer
}

@keyframes fade-in-footer {
  0% {
    background-color: rgba(24, 24, 24, 1);
  }

  100% {
    background-color: transparent
  }
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-left span {
  font-size: 4rem;
}

.footer-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.footer .vertical-rule {
  height: 200px;
  border-left: 1px solid #cecec2;
}

.footer-right div {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.footer hr {
  width: 20%;
  display: none;
  margin-bottom: 32px;
}

.footer-contact {
  align-items: end;
}

.footer-contact span:hover {
  cursor: pointer;
  transform: translateY(-25%);
  transition: transform .5s ease
}

.footer-socials span:hover {
  cursor: pointer;
  transform: translateY(-25%);
  transition: transform .5s ease;
}

.show {
  display: block;
}

@media(max-width: 900px) {

  .home-page-left {
    max-width: 80%;
  }

  #about {
    align-items: center;
    padding: 0 10vw;
  }

  .content {
    text-align: center;
  }

  .home-title {
    font-size: 3.5rem;
  }

  #navbar-links li{
    display: none;
  }

  .navbar-div {
    padding: 0 0 0 24px;
    width: 100%;
  }

  #menu-icon{
    padding-right: 12px;
  }

  #close-icon {
    padding-right: 12px;
  }

  #navbar-links {
    display: none;
  }


  .footer hr {
    display: block;
  }

  .vertical {
    display: flex;
    flex-direction: column;
  }

  .hide {
    visibility: hidden;
  }

  #vertical-rule {
    display: none;
  }

  .footer-right> div {
    flex-direction: row;
  }

  .footer-left {
    flex-direction: row;
    gap: 12px;
  }

  .wide {
    width: 80%;
  }

  .home-page-right {
    font-size: 24px;
  }

  .preview-photo {
    max-width: 70%;
  }

  .preview .description {
    width: 70%;
    text-align: center;
  }

  .navbar-right {
    padding-right: 24px;
  }

  .about-paragraph {
    width: 80%;
  }

  .about-right {
    align-items: center;
  }

}

@media (max-width: 500px) {
  #portrait {
    transform: scale(.8)
  }

  .home-title {
    font-size: 2.5rem;
  }

  .home-page-right {
    text-align: center;
  }

  .home-page-top {
    gap: none;
    padding: 0;
  }

  .home-page {
    padding: 17vh 0 15vh 0;
  }

  #about {
    padding: 0 10vw 5vh 10vw;
    gap: 16px;
  }

  .about-right {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }

  .about-paragraph {
    width: 100%;
  }

  #projects {
    padding: 5vh 0;
    width: 100%;
    box-sizing: border-box;
  }

  .preview-photo:hover {
    transform: scale(.95);
    cursor: pointer;
  }
  .preview-photo img:hover {
    transform: scale(.6);
    cursor: pointer;
  }

  .preview-photo {
    width: 75%;
    height: 200px;
  }

  .preview-photo img {
    width: 275px;
    height: auto;
  }

  .preview ul {
    font-size: x-small;
    padding: 24px 6px;
  }

  .preview .description {
    width: 90%
  }

  .footer>* {
    transform: scale(.8);
  }

  .footer {
    padding-bottom: 24px;
  }

  .navbar-div {
    width: 100%;
    backdrop-filter: blur(3px);
  }

  #menu-icon {
    padding-right: 36px;
  }

  #close-icon {
    padding-right: 12px;
  }

}

@media (min-width: 900px) {
  #menu-icon {
    display: none;
  }
}